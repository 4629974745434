import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Spinner, Alert, Table, Modal } from 'react-bootstrap';
import config from '../../config';
import fetchWithAuth from '../../util/fetchWithAuth';
import { useExecuteRecaptcha } from '../../util/ReCaptcha';
import './css/PurchaseCredits.css'; // Make sure this path is correct

function PurchaseCredits() {
  const [creditDetails, setCreditDetails] = useState([]);
  const [selectedCredit, setSelectedCredit] = useState('');
  const [bankDetails, setBankDetails] = useState([]);
  const [selectedBank, setSelectedBank] = useState('');
  const [uniqueCode, setUniqueCode] = useState('');
  const [credits, setCredits] = useState('');
  const [price, setPrice] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { execute } = useExecuteRecaptcha();

  useEffect(() => {
    const fetchCreditDetails = async () => {
      try {
        const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getCreditDetails}`);

        if (response.ok) {
          const jsonData = await response.json();

          if (jsonData.result.success) {
            const creditData = Object.entries(jsonData.result.data).map(([id, details]) => ({
              id,
              ...details,
            }));
            setCreditDetails(creditData);
          } else {
            setError(jsonData.result.message || 'Failed to fetch credit details');
          }
        } else {
          setError('Failed to fetch credit details');
        }
      } catch (error) {
        setError('Network error');
      } finally {
        setLoading(false);
      }
    };

    const checkUniqueCode = async () => {
      setLoading(true);
      try {
        const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getPendingPurchaseHistory}`);

        if (response.ok) {
          const jsonData = await response.json();

          if (jsonData.result.success && jsonData.result.uniqueCode) {
            setUniqueCode(jsonData.result.uniqueCode);
            setCredits(jsonData.result.credits);
            setPrice(jsonData.result.price);
            setPaymentStatus(jsonData.result.paymentStatus);
            fetchBankDetails();
          }
        }
      } catch (error) {
        console.error('Failed to check unique code:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCreditDetails();
    checkUniqueCode();
  }, []);

  const fetchBankDetails = async () => {
    setLoading(true);
    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.getBankDetails}`);

      if (response.ok) {
        const jsonData = await response.json();

        if (jsonData.result.success) {
          setBankDetails(Object.entries(jsonData.result.data).map(([id, details]) => ({
            id,
            ...details,
          })));
          setSelectedBank(Object.entries(jsonData.result.data)[0][0]); // Select the first bank by default
        } else {
          setError(jsonData.result.message || 'Failed to fetch bank details');
        }
      } else {
        setError('Failed to fetch bank details');
      }
    } catch (error) {
      setError('Network error');
    } finally {
      setLoading(false);
    }
  };

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setSelectedCredit(value);
  };

  const handleBankChange = (event) => {
    const { value } = event.target;
    setSelectedBank(value);
  };

  const handlePurchase = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    const selected = creditDetails.find(credit => credit.id === selectedCredit);

    if (!selected) {
      setError('Please select a credit option');
      setLoading(false);
      return;
    }

    const reCaptchaToken = await execute('purchaseCredits');
    const body = {
      id: selected.id,
      price: selected.price,
      credits: selected.credits,
      reCaptchaToken,
    };

    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.purchaseCredits}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const jsonData = await response.json();

      if (response.ok && jsonData.result.success) {
        setSuccess('Purchase successful');
        
        setUniqueCode(jsonData.result.uniqueCode);
        setCredits(jsonData.result.credits);
        setPrice(jsonData.result.price);
        setPaymentStatus('PENDING');
        fetchBankDetails();
      } else {
        setError(jsonData.result.message || 'Failed to purchase credits');
      }
    } catch (error) {
      setError('Network error');
    } finally {
      setLoading(false);
    }
  };

  const handleCompletePurchase = async () => {
    setShowCompleteModal(false);
    setLoading(true);
    setError('');
    setSuccess('');

    const token = await execute('completePurchase');
    const body = {
      uniqueCode: uniqueCode,
      bankId: selectedBank,
      reCaptchaToken: token,
    };

    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.completePurchase}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const jsonData = await response.json();

      if (response.ok && jsonData.result.success) 
      {
        setSuccess(jsonData.result.message || 'Purchase completed successfully');
        setUniqueCode('');
      } 
      else 
      {
        setError(jsonData.result.message || 'Failed to complete purchase');
      }
    } catch (error) {
      setError('Network error');
    } finally {
      setLoading(false);
    }
  };

  const handleCancelPurchase = async () => {
    setShowCancelModal(false);
    setLoading(true);
    setError('');
    setSuccess('');

    const token = await execute('cancelPurchase');
    const body = {
      uniqueCode: uniqueCode,
      reCaptchaToken: token,
    };

    try {
      const response = await fetchWithAuth(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.cancelPurchase}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const jsonData = await response.json();

      if (response.ok && jsonData.result.success) {
        setSuccess(jsonData.result.message || 'Purchase cancelled successfully');
        setUniqueCode('');
      } else {
        setError(jsonData.result.message || 'Failed to cancel purchase');
      }
    } catch (error) {
      setError('Network error');
    } finally {
      setLoading(false);
    }
  };

  const formatPrice = (price) => `SGD $${Number(price).toFixed(2)}`;
  const formatCredits = (credits) => credits ? credits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'N/A';

  return (
    <Container className="purchase-credits-container">
      <div className="purchase-credits-card">
        <h1>Purchase Credits</h1>
        {loading ? (
          <Spinner animation="border" role="status" className="d-block mx-auto">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : uniqueCode ? (
          <>
            {bankDetails.length > 0 && (
              <>
                <Form.Group>
                  <Form.Label>Select Bank</Form.Label>
                  <Form.Control as="select" value={selectedBank} onChange={handleBankChange}>
                    {bankDetails.map((bank) => (
                      <option key={bank.id} value={bank.id}>
                        Bank: {bank.bankName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Table striped bordered hover className="mt-3">
                  <tbody>
                    <tr>
                      <td>Bank Name:</td>
                      <td>{bankDetails.find(bank => bank.id === selectedBank)?.bankName}</td>
                    </tr>
                    <tr>
                      <td>Bank Account No:</td>
                      <td>{bankDetails.find(bank => bank.id === selectedBank)?.accountNo} <span style={{ color: 'red' }}> <br></br>(Omit dashes when entering in bank account)</span></td>
                    </tr>
                    <tr>
                      <td>Unique Code:<br></br>(case-sensitive)</td>
                      <td>{uniqueCode} <span style={{ color: 'red' }}> <br></br>(Enter this in the transaction comments)</span></td>
                    </tr>
                  </tbody>
                </Table>
                <Table striped bordered hover className="mt-3">
                  <tbody>
                    <tr>
                      <td>Credits:</td>
                      <td>{formatCredits(credits)}</td>
                    </tr>
                    <tr>
                      <td>Price:</td>
                      <td>{formatPrice(price)}</td>
                    </tr>
                    <tr>
                      <td>Payment Status:</td>
                      <td style={{ color: paymentStatus === 'PENDING' ? 'orange' : 'black' }}>{paymentStatus}</td>
                    </tr>
                  </tbody>
                </Table>
                <Button variant="primary" className="mt-3" onClick={() => setShowCompleteModal(true)}>
                  Complete Purchase
                </Button>
                <Button variant="secondary" className="mt-3" onClick={() => setShowCancelModal(true)}>
                  Cancel Purchase
                </Button>
                {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
              </>
            )}
          </>
        ) : (
          <Form onSubmit={handlePurchase} className="mt-4">
            <Form.Group>
              <Form.Label>Select Credits</Form.Label>
              {creditDetails.map(credit => (
                <Form.Check
                  type="radio"
                  key={credit.id}
                  id={`credit-${credit.id}`}
                  label={`${formatPrice(credit.price)} - ${formatCredits(credit.credits)} credits`}
                  value={credit.id}
                  checked={selectedCredit === credit.id}
                  onChange={handleRadioChange}
                  className="form-check"
                />
              ))}
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Proceed to Purchase Credits
            </Button>
            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
            {success && <Alert variant="success" className="mt-3">{success}</Alert>}
          </Form>
        )}
      </div>
      <Modal show={showCompleteModal} onHide={() => setShowCompleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Complete Purchase</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please ensure you have made the payment. Any false transaction will lead to an account ban.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCompleteModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCompletePurchase}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Cancel Purchase</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You can make a new purchase after cancelling, but too many consecutive cancellations will lead to an account ban.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCancelPurchase}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default PurchaseCredits;
