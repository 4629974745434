export const validatePassword = (password) => {
    const lengthValid = password.length >= 6 && password.length <= 12;
    const upperCaseValid = /[A-Z]/.test(password);
    const lowerCaseValid = /[a-z]/.test(password);
    const numberValid = /[0-9]/.test(password);
    const noSpaces = !/\s/.test(password);
    const characterTypes = [upperCaseValid, lowerCaseValid, numberValid].filter(Boolean).length >= 2;
  
    let errorMessage = '';
  
    if (!lengthValid) {
      errorMessage += 'Password must be between 6 and 12 characters long. ';
    }
    if (!noSpaces) {
      errorMessage += 'Password must not contain spaces. ';
    }
    if (!upperCaseValid) {
      errorMessage += 'Password must contain at least one uppercase letter. ';
    }
    if (!lowerCaseValid) {
      errorMessage += 'Password must contain at least one lowercase letter. ';
    }
    if (!numberValid) {
      errorMessage += 'Password must contain at least one number. ';
    }
    if (!characterTypes) {
      errorMessage += 'Password must contain at least two of the following: uppercase letters, lowercase letters, or numbers. ';
    }
  
    return errorMessage.trim();
  };